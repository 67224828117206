<template>
  <v-list density="comfortable" :class="rail ? 'pt-0' : undefined">
    <side-menu-item v-for="menuItem in primaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <v-divider class="my-2" />

    <side-menu-item v-for="menuItem in secondaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />
  </v-list>
</template>

<script setup lang="ts">
import type { MenuItem } from "./SideMenuItem.vue"

defineProps<{ rail?: boolean }>()

const pumpingStationsDomainQuery = `fr_domain=${encodeURIComponent(JSON.stringify([DomainType.PUMPING_STATIONS]))}`
const civilDomainQuery = `fr_domain=${encodeURIComponent(JSON.stringify([DomainType.CIVIL]))}`

const primaryMenuList: Array<MenuItem> = [
  {
    title: "admin.title",
    icon: "$adminPanel",
    to: "/admin",
    exact: true,
  },
  {
    title: "admin.organizations.title",
    icon: "$organization",
    to: "/admin/organizations",
    hideDropdownTitle: true,
    children: [
      {
        title: "admin.organizations.customer.title",
        icon: "$customer",
        to: `/admin/organizations?fr_type=${encodeURIComponent(JSON.stringify([OrganizationType.CUSTOMER]))}`,
      },
      {
        title: "admin.organizations.supplier.title",
        icon: "$supplier",
        to: `/admin/organizations?fr_type=${encodeURIComponent(JSON.stringify([OrganizationType.SUPPLIER]))}`,
      },
      {
        title: "admin.organizations.label.title",
        icon: "$label",
        to: `/admin/organizations?fr_type=${encodeURIComponent(JSON.stringify([OrganizationType.LABEL]))}`,
      },
    ],
  },
  {
    title: "admin.users.title",
    icon: "$users",
    to: "/admin/users",
  },
  {
    title: "admin.assets.titlePumpingStations",
    icon: "$domain_pumping_stations",
    to: "/admin/assets",
    hideDropdownTitle: true,
    children: [
      {
        title: "admin.assets.templates.title",
        icon: "$assetTemplates",
        to: `/admin/assets/templates-${DomainType.PUMPING_STATIONS}?${pumpingStationsDomainQuery}`,
      },
      {
        title: "assets.structure.title",
        icon: "$assetStructure",
        to: `/admin/assets/structure/${DomainType.PUMPING_STATIONS}`,
      },
      {
        title: "admin.assets.lookupTypes.title",
        icon: "$lookupType",
        to: `/admin/assets/lookup-types-${DomainType.PUMPING_STATIONS}?${pumpingStationsDomainQuery}`,
      },
      {
        title: "admin.assets.componentTypes.title",
        icon: "$componentType",
        to: `/admin/assets/component-types-${DomainType.PUMPING_STATIONS}?${pumpingStationsDomainQuery}`,
      },
      {
        title: "admin.defaultListValues.menuTitle",
        icon: "$listValue",
        to: `/admin/list-values-${DomainType.PUMPING_STATIONS}`,
      },
    ],
  },
  {
    title: "admin.assets.titleCivil",
    icon: "$domain_civil",
    to: "/admin/assets",
    hideDropdownTitle: true,
    children: [
      {
        title: "admin.assets.templates.title",
        icon: "$assetTemplates",
        to: `/admin/assets/templates-${DomainType.CIVIL}?${civilDomainQuery}`,
      },
      {
        title: "assets.structure.title",
        icon: "$assetStructure",
        to: `/admin/assets/structure/${DomainType.CIVIL}`,
      },
      {
        title: "admin.assets.lookupTypes.title",
        icon: "$lookupType",
        to: `/admin/assets/lookup-types-${DomainType.CIVIL}?${civilDomainQuery}`,
      },
      {
        title: "admin.assets.componentTypes.title",
        icon: "$componentType",
        to: `/admin/assets/component-types-${DomainType.CIVIL}?${civilDomainQuery}`,
      },
      {
        title: "admin.defaultListValues.menuTitle",
        icon: "$listValue",
        to: `/admin/list-values-${DomainType.CIVIL}`,
      },
    ],
  },
  {
    title: "admin.assets.lookupValues.title",
    icon: "$lookupValue",
    to: `/admin/assets/lookup-values`,
  },
  {
    title: "admin.assets.assetList.title",
    icon: "$asset",
    to: "/admin/assets/asset-list",
  },
  {
    title: "admin.releaseNotes.title",
    icon: "$releaseNotes",
    to: "/admin/release-notes",
  },
]

const secondaryMenuList: Array<MenuItem> = [
  {
    title: "admin.scripts.title",
    icon: "$scripts",
    to: "/admin/scripts",
  },
  {
    title: "admin.securityLogs.title",
    icon: "$securityLogs",
    to: "/admin/security-logs",
  },
  {
    title: "mutationLog.title",
    icon: "$mutationLogs",
    to: "/admin/mutation-log",
  },
]
</script>
